import React from 'react'
import './style/index.scss'
import Route from './route'
import store from './store'
import { Provider } from 'react-redux'
import { ToastProvider } from 'react-toast-notifications'

function App() {
    return (
        <Provider store={store}>
            <ToastProvider
                placement="top-center"
                autoDismissTimeout={2000}
            >
                <Route/>
            </ToastProvider>
        </Provider>
    )
}

export default App
