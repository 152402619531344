export const ARTICLE_CONTENT_TYPE = {
    'ZW': 1,
    'JTCT': 2,
    'JT': 3,
    'TY': 4,
    'KEYPOINT': 5,
    'SYNO': 6 
}

export const SUB_CATE_DICT = {
    'shuxin': '书信',
    'qita': '其他',
    'xieren': '写人',
    'xiejing': '写景',
    'xushi': '叙事',
    'xiaoshuo': '小说',
    'sanwen': '散文',
    'riji': '日记',
    'zhuangwu': '状物',
    'huati': '话题',
    'yanjianggao': '演讲稿',
    'yilun': '议论文',
    'shuoming': '说明文',
    'duhougan': '读后感',
    'dushubiji': '读书笔记',
    'yingyong': '应用文',
    'shige': '诗歌',
    'yuyan': '寓言',
    'xiangxiang': '想象',
    'youji': '游记',
    'kantu': '看图',
    'tonghua': '童话',
}

export const COVER_LABE = {
    'p1': '一年级',
    'p2': '一年级',
    'p3': '二年级',
    'p4': '二年级',
    'p5': '三年级',
    'p6': '三年级',
    'p7': '四年级',
    'p8': '四年级',
    'p9': '五年级',
    'p10': '五年级',
    'p11': '六年级',
    'p12': '六年级',
    'm1': '七年级',
    'm2': '七年级',
    'm3': '八年级',
    'm4': '八年级',
    'm5': '九年级',
    'm6': '九年级',
    'h1': '高一',
    'h2': '高一',
    'h3': '高二',
    'h4': '高二',
    'h5': '高三',
    'h6': '高三',
}

export const FILTER_GRADES = [
    '',
    'gp', 'g1', 'g2', 'g3', 'g4', 'g5', 'g6',
    'gm', 'g7', 'g8', 'g9',
    'gh', 'g10', 'g11', 'g12'
]

export const unitNumChinese = [
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
    '十',
    '十一',
    '十二',
    '十三',
    '十四',
    '十五',
    '十六',
    '十七',
    '十八',
    '十九',
    '二十'
]

export const articleNum = [
    '', 200, 300, 400, 500, 600, 800, 1000
]

export const GRADE_DICT = {
    'g1': '一年级',
    'g2': '二年级',
    'g3': '三年级',
    'g4': '四年级',
    'g5': '五年级',
    'g6': '六年级',
    'g7': '初一',
    'g8': '初二',
    'g9': '初三',
    'g10': '高一',
    'g11': '高二',
    'g12': '高三',
}

export const UNIT_NAME_DICT = {
    '1': '第一单元',
    '2': '第二单元',
    '3': '第三单元',
    '4': '第四单元',
    '5': '第五单元',
    '6': '第六单元',
    '7': '第七单元',
    '8': '第八单元',
    '9': '第九单元',
    '10': '第十单元',
}

export const GRADE_S_DICT = {
    'p1': '一年级·上',
    'p2': '一年级·下',
    'p3': '二年级·上',
    'p4': '二年级·下',
    'p5': '三年级·上',
    'p6': '三年级·下',
    'p7': '四年级·上',
    'p8': '四年级·下',
    'p9': '五年级·上',
    'p10': '五年级·下',
    'p11': '六年级·上',
    'p12': '六年级·下',
    'm1': '七年级·上',
    'm2': '七年级·下',
    'm3': '八年级·上',
    'm4': '八年级·下',
    'm5': '九年级·上',
    'm6': '九年级·下',
}
