import {
    PATCH_SEARCH,
    PATH_SEARCH_HISTORY,
    FETCH_SUGGESTION_SUCCESS,
    FETCH_RESULT_SUCCESS
} from './type'
import { setStorage, getStorage } from './../../utils'

export { searchSaga } from './saga'

export const searchState = {
    query: '',
    history: (getStorage('search') || {}).history || [],
    suggestions: [],
    result: {
        zw: [],
        line: []
    }
}

export const searchReducer = (state = searchState, action) => {
    switch (action.type) {

    case PATCH_SEARCH:
        return {
            ...state,
            query: action.query
        }

    case PATH_SEARCH_HISTORY:
        state.history = [...(action.history || [])].slice(0, 20)
        setStorage('search', state)
            
        return {
            ...state
        }

    case FETCH_SUGGESTION_SUCCESS:
        return {
            ...state,
            suggestions: action.suggestions
        }
    case FETCH_RESULT_SUCCESS:
        return searchResultReducer(state, action)
        
    default:
        return state
    }
}

function searchResultReducer(state, action) {
    return {
        ...state,
        result: action.result
    }
}