const storageKey = 'bishenStorage'

export const getStorage = (key) => {
    try {
        const storage = JSON.parse(localStorage.getItem(storageKey) || '{}')
    
        if (!key) {
            return storage
        }

        return storage[key]
    } catch (e) {
        return false
    }
}

export const setStorage = (key, value) => {
    try {
        const storage = getStorage()
        storage[key] = value
        localStorage.setItem(storageKey, JSON.stringify(storage || {}))

        return true
    } catch (e) {
        return false
    }
}

export const clearStorage = (key) => {
    try {
        if (key) { 
            setStorage(key, undefined)
        } else {
            localStorage.setItem(storageKey, JSON.stringify({}))
        }

        return true
    } catch (e) {
        return false
    }
}
