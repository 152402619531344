import {
    PATCH_USER
} from './type'
import { setStorage } from './../../utils'

export { userSaga } from './saga'

export const userState = {
    gradeValue: undefined
}

export const userReducer =  (state = userState, action) => {
    switch (action.type) {
    case PATCH_USER:
        const user = {
            ...state,
            gradeValue: action.gradeValue
        } 
        setStorage('user', user)
            
        return  user
    default:
        return state
    }
}