import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './flexble.js'
import vConsole from 'vconsole'
ReactDOM.render(<App />, document.getElementById('root'))

// new vConsole()

// const ua = window.navigator.userAgent.toLowerCase()
// console.log('ua ========================> ', ua.indexOf('miniprogramhtmlwebview'), ua) 

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
