const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "index" */ './../pages/index'),
        title: '主页',
        auth: [],
    },
    {
        path: '/search/:query',
        component: () =>
    import(/* webpackChunkName: "index" */ './../pages/search'),
        title: '搜索',
        auth: [],
    },
    {
        path: '/search',
        component: () =>
    import(/* webpackChunkName: "index" */ './../pages/search'),
        title: '搜索',
        auth: [],
    },
    {
        path: '/unit',
        component: () =>
    import(/* webpackChunkName: "unit" */ './../pages/unit/Unit'),
        title: '单元',
        auth: [],
    },
    {
        path: '/unit/:id',
        component: () =>
    import(/* webpackChunkName: "unitArticle" */ './../pages/unit/Article'),
        title: '单元作文',
        auth: [],
    },
    {
        path: '/article/:id',
        component: () =>
    import(/* webpackChunkName: "Article" */ './../pages/article'),
        title: '作文',
        auth: [],
    },
    {
        path: '/propaganda/dy',
        component: () =>
    import(/* webpackChunkName: "dy" */ './../pages/propaganda/dy'),
        title: '抖音活动页面',
        auth: [],
    },
    // {
    //     path: '/player',
    //     component: () => import(/* webpackChunkName: "dy" */  './../pages/player'),
    //     title: 'Player',
    //     auth: []
    // }
]

export default routes
