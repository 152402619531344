/**
 * 判断是否为微信小程序
 *
 * @export
 * @returns
 */
export function isWeChatApplet() {
    const ua = window.navigator.userAgent.toLowerCase()
    return ua.indexOf('miniprogram') !== -1
}

export function isWeChatAppletOrQQ() {
    const ua = window.navigator.userAgent.toLowerCase()
    return ua.indexOf('miniprogram') !== -1
        || ua.indexOf(' qq') > -1 && ua.indexOf('mqqbrowser') < 0
        || ua.match(/MicroMessenger/i) == 'micromessenger'
        || ua.indexOf('mqqbrowser') > -1
}

export function myBrowser(){
    let userAgent = navigator.userAgent   //取得浏览器的userAgent字符串
    let isOpera = userAgent.indexOf('Opera') > -1 
    if (isOpera) {
        return 'Opera'
    }  //判断是否Opera浏览器
    if (userAgent.indexOf('Firefox') > -1) {
        return 'FF' 
    } //判断是否Firefox浏览器
    if (userAgent.indexOf('Chrome') > -1){
        return 'Chrome' 
    }
    if (userAgent.indexOf('Safari') > -1) {
        return 'Safari' 
    } //判断是否Safari浏览器
    if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera) {
        return 'IE' 
    }  //判断是否IE浏览器
}

export function isSoGou() {
    return location.host === 'shouji.sogou.com'
}