import request from './request'

export const fetchUnitSubjectApi = ({id='p5_1_6'}) =>
    request.get(`/api/v2/unit/subjects/${id}`)

export const fetchUnitArticleApi = ({id=''}) => 
    request.get(`/api/v2/unit/articles/${id}`)

export const fetchCourseGradeListApi =  () => 
    request.get('/api/v2/unit/grades/meta', {})

export const fetchUnitListApi = ({ id = '' }) =>
    request.get(`/api/v2/unit/grades/${id}`, {})