import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import loadable from '@loadable/component'
import routeData from './routes'
import querystring from 'querystring'

const history = createBrowserHistory()

export default () => {
    const [routes, setRoutes] = React.useState(routeData)

    const data = routes.map((r) => {
        r.component = loadable(r.component)

        return r
    })

    return (
        <BrowserRouter
            history={history}
            basename={process.env.NODE_ENV === 'development' ? '/' : '/wap/h5cooper/bishen'}
        >
            <Switch>
                {
                    data.map((r, index) => 
                        <Route
                            key={r.path}
                            path={r.path}
                            component={r.component}
                            exact={true}
                        />
                    )
                }
            </Switch>
        </BrowserRouter>
    )
}