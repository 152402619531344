import {
    FETCH_UNIT_ARTICLE_FAIL,
    FETCH_UNIT_ARTICLE_SUCCESS,
    FETCH_UNIT_SUBJECT_SUCCESS,
    FETCH_UNIT_SUBJECT_FAIL,
    PATCH_UNIT_ARTICLE,
    FETCH_GRADE_SUCCESS,
    FETCH_GRADE_FAIL,
    FETCH_UNIT_SUCCESS,
    FETCH_UNIT_FAIL,
    PATCH_UNIT
} from './type'
import { ARTICLE_CONTENT_TYPE } from './../../config'

export { unitSaga } from './saga'

export const unitState = {
    subject: [],
    unitArticles: {},
    grade: {
        high: [],
        middle: [],
        primary: []
    },
    unit: {}
}

export const unitReducer =  (state = unitState, action) => {
    switch (action.type) {
    case FETCH_UNIT_ARTICLE_SUCCESS:
        return fetchUnitArticleSuccess(state, action)
        
    case FETCH_UNIT_ARTICLE_FAIL:
        return state  
    case FETCH_UNIT_SUBJECT_SUCCESS:
            
        return {
            ...state,
            subject: action.subject
        } 
    case FETCH_UNIT_SUBJECT_FAIL:
        return {
            ...state
        } 
    case PATCH_UNIT_ARTICLE:
        return {
            ...state,
            unitArticles: action.articles 
        }  
    case FETCH_GRADE_SUCCESS:
        return {
            ...state,
            grade: action.grade
        }
    case FETCH_GRADE_FAIL:
        return {
            ...state
        } 
    case FETCH_UNIT_SUCCESS:
        state.unit[action.id] = action.units
            
        return {
            ...state,
        } 
    case FETCH_UNIT_FAIL:
        return {
            ...state
        } 
        
    case PATCH_UNIT:
        return {
            ...state,
            unit: action.unit
        }    
    default:
        return state
    }
}

function fetchUnitArticleSuccess(state, action) {
    let { article } = action
    let hadFirstJTCT = false

    console.log(article)
    

    article.titleSubs = article.titleSubs.map((t) => {
        if (!hadFirstJTCT && t.type === ARTICLE_CONTENT_TYPE.JTCT) {
            hadFirstJTCT = true
            t.firstJTCT = true
        }

        return t
    })

    article.content = article.content.map((t) => {
        if (!hadFirstJTCT && t.type === ARTICLE_CONTENT_TYPE.JTCT) {
            hadFirstJTCT = true
            t.firstJTCT = true
        }

        return t
    })

    state.unitArticles[article.id] = article

    return {
        ...state
    }
}