import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { unitReducer, unitState, unitSaga } from './unit'
import { userSaga, userState, userReducer } from './user'
import { searchSaga, searchState, searchReducer } from './search'

const rootReducer = combineReducers({
    unit: unitReducer,
    user: userReducer,
    search: searchReducer
})
const rootState = {
    unit: unitState,
    user: userState,
    search: searchState
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()

const store = createStore(rootReducer, rootState, /* preloadedState, */ composeEnhancers(applyMiddleware(sagaMiddleware, ({ getState }) => {
    return next => action => {
        console.log(`${action.type}: `, action)

        const returnValue = next(action)

        return returnValue
    }
})))

const sagas = [
    unitSaga,
    userSaga,
    searchSaga
]

for (const saga of sagas) {
    sagaMiddleware.run(saga)

}

export default store
