import axios from 'axios'
import { isSoGou, } from '../utils/platform'

export const BISHEN_API_HOST = isSoGou() ? 'https://sogou.thd.bishen.ink' : 'https://v2.api.bishen.ink'
export const BISHEN_ZUOWEN_API_HOST = 'http://zw.api.bishen.ink:8000'

//keep these api here for developement -- yangyonglei
// export const BISHEN_LOCAL_API_HOST = 'http://localhost:8080'
export const BISHEN_LOCAL_API_HOST = 'https://bishen.cauyyl.com:8000'

// inremental: to identify new clients
const CLIENT_SEQ = 1

//const BISHEN_API_HOST = 'http://localhost:5002'
//
const BISHEN_API_INSTANCE = axios.create({
    baseURL: BISHEN_API_HOST,
    headers: {
        //'Token': localStorage.getItem('user_token'),
        //Token: 'eyJhbGciOiJIUzI1NiIsImlhdCI6MTUzMTIwNTQxNywiZXhwIjoxNTMxODEwMjE3fQ.eyJpZCI6MTQsInV1aWQiOiJkOWI1Yzk3NjRhOGU0NjRhOGEyY2U2ZDM5NTI2YzQ1NCIsInJvbGUiOjMsIm5vbmNlIjoiNGN5aDZ0cmoifQ.7Fsd-Q9ZEnL7rH8Lm86MFlZ099b9p6mBNy8qz0nFeuY',
        // 'X-Client-Type': 'zw-app',
        // 'X-Client-Seq': CLIENT_SEQ,
    },
    timeout: 15 * 1000,
})

BISHEN_API_INSTANCE.interceptors.request.use((req) => {
    // req.headers['Content-Type'] = undefined

    return req
})

BISHEN_API_INSTANCE.interceptors.response.use((res) => {
    if (res.data.code == 2) {
        return Promise.resolve(res.data) 
    }
    if (res.data.code === 0) {
        return Promise.resolve(res.data) 
    } else {
        return Promise.reject(res) 
    }
})

export const BISHEN_ZUOWEN_API_INSTANCE = axios.create({
    baseURL: BISHEN_ZUOWEN_API_HOST,
    headers: {
        'X-Client-Type': 'zw-app',
        'X-Client-Seq': CLIENT_SEQ,
    },
    timeout: 15 * 1000,
})

//keep BISHEN_ZUOWEN_API_LOCAL for developement -- yangyonglei
export const BISHEN_ZUOWEN_API_LOCAL = axios.create({
    baseURL: BISHEN_LOCAL_API_HOST,
    headers: {
        'X-Client-Type': 'zw-app',
        // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    timeout: 15 * 1000,
})

export function updateClientToken(token) {
    BISHEN_API_INSTANCE.defaults.headers['Token'] = token
    BISHEN_ZUOWEN_API_INSTANCE.defaults.headers['Token'] = token
}

export default BISHEN_API_INSTANCE
