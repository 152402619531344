import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { 
    FETCH_SUGGESTION_REQ,
    FETCH_SUGGESTION_SUCCESS,
    FETCH_SUGGESTION_FAIL,
    FETCH_RESULT_REQ,
    FETCH_RESULT_SUCCESS,
    FETCH_RESULT_FAIL
} from './type'
import {
    fetchSuggestionQueryApi,
    fetchSearchResultApi,
    fetchSearchUnitResultApi,
    fetchSearchLineResultApi
} from './../../api/search'

function* fetchSuggestionSaga(action) {
    try {
        const res = yield call(fetchSuggestionQueryApi, action)

        yield put({
            type: FETCH_SUGGESTION_SUCCESS,
            suggestions: res.queries || []
        })


        if (action.onSuccess) {
            action.onSuccess({res})
        }
    } catch (e) {
        yield put({
            type: FETCH_SUGGESTION_FAIL
        })

        if (action.onFail) {
            action.onFail()
        }
    }
}

function* fetchSearchResultSaga(action) {
    const result = {
        zw: [],
        line: []
    }
    try {
        console.log('action ========================> ', action)
        if (action.fetchType === 'zw') { 
            const res = yield call(fetchSearchResultApi, action)
            const articles = []

            // const unitRes = yield call(fetchSearchUnitResultApi, action)

            // for (let unit of unitRes.units.slice(0, 2)) {
            //     unit.type = 'unit'
            //     articles.push(unit)
            // }

            for (let article of res.articles) {
                article.type = 'zw'
                articles.push(article)
            }

            result.zw = articles
        } else {
            // const lineRes = yield call(fetchSearchLineResultApi, action)
            // result.line = lineRes.lines
        }

        yield put({
            type: FETCH_RESULT_SUCCESS,
            result
        })

        console.log('search res ========================> ', result)


        if (action.onSuccess) {
            action.onSuccess({result})
        }
    } catch (e) {
        yield put({
            type: FETCH_RESULT_FAIL
        })

        if (action.onFail) {
            action.onFail()
        }
    }
}

export const searchSaga = function* () {
    yield takeEvery(FETCH_SUGGESTION_REQ, fetchSuggestionSaga)
    yield takeEvery(FETCH_RESULT_REQ, fetchSearchResultSaga)
}