import request from './request'

export const fetchSuggestionQueryApi = ({ id = '' }) =>
    request.get('/api/zw/hot/query')

export const fetchSearchResultApi = (payload = { query: '' }) =>
    request.post('/api/mini/zw/search', payload)

export const fetchSearchUnitResultApi = (payload = { query: '' }) =>
    request.post('/api/mini/unit/search', payload)

export const fetchSearchLineResultApi = (payload = { query: '' }) =>
    request.get('/api/mini/zw/search-lines', {
        params: {
            query: payload.query
        }
    })   