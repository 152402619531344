import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import {
    FETCH_UNIT_ARTICLE_REQ,
    FETCH_UNIT_ARTICLE_SUCCESS,
    FETCH_UNIT_ARTICLE_FAIL,
    FETCH_UNIT_SUBJECT_REQ,
    FETCH_UNIT_SUBJECT_SUCCESS,
    FETCH_UNIT_SUBJECT_FAIL,
    FETCH_GRADE_REQ,
    FETCH_GRADE_SUCCESS,
    FETCH_GRADE_FAIL,
    FETCH_UNIT_REQ,
    FETCH_UNIT_SUCCESS,
    FETCH_UNIT_FAIL,
} from './type'
import {
    fetchUnitSubjectApi,
    fetchUnitArticleApi,
    fetchCourseGradeListApi,
    fetchUnitListApi,
} from './../../api/unit'

function* fetchUnitSubject(action) {
    try {
        const res = yield call(fetchUnitSubjectApi, action)
        const subject = res.articles || []

        yield put({ type: FETCH_UNIT_SUBJECT_SUCCESS, subject })

        yield put({
            type: FETCH_UNIT_ARTICLE_REQ,
            id: subject[0] ? subject[0].id : '',
        })

        for (const s of subject.slice(1)) {
            yield put({
                type: FETCH_UNIT_ARTICLE_REQ,
                id: s.id,
            })
        }
    } catch (e) {
        yield put({ type: FETCH_UNIT_SUBJECT_FAIL, err: e.message })
    }
}

function* fetchUnitArticle(action) {
    try {
        const res = yield call(fetchUnitArticleApi, action)
        if (res.article) {
            res.article.content = res.article.content.map((a, index) => {
                a.hash = a.text + index

                return a
            })
        }

        yield put({ type: FETCH_UNIT_ARTICLE_SUCCESS, article: res.article })
    } catch (e) {
        yield put({ type: FETCH_UNIT_ARTICLE_FAIL, err: e.message })
    }
}

function* fetchGrade(action) {
    try {
        const res = yield call(fetchCourseGradeListApi, action)

        console.log('grade ========================> ', res)
        const grade = {
            high: res.high,
            middle: res.middle,
            primary: res.primary,
        }

        yield put({ type: FETCH_GRADE_SUCCESS, grade })
        yield put({
            type: FETCH_UNIT_REQ,
            id: action.grade.id,
            unit: action.grade.unit || 1,
        })
    } catch (e) {
        yield put({ type: FETCH_GRADE_FAIL, err: e.message })
    }
}

function* fetchUnit(action) {
    try {
        const res = yield call(fetchUnitListApi, action)

        res.units.index = action.unit || 1

        console.log('unit xxxx ========================> ', action, res)

        yield put({
            type: FETCH_UNIT_SUCCESS,
            id: action.id,
            units: res.units,
        })
    } catch (e) {
        yield put({ type: FETCH_UNIT_FAIL, err: e.message })
    }
}

export const unitSaga = function*() {
    yield takeEvery(FETCH_UNIT_ARTICLE_REQ, fetchUnitArticle)
    yield takeEvery(FETCH_UNIT_SUBJECT_REQ, fetchUnitSubject)
    yield takeEvery(FETCH_GRADE_REQ, fetchGrade)
    yield takeEvery(FETCH_UNIT_REQ, fetchUnit)
}
