export const FETCH_UNIT_ARTICLE_REQ = 'FETCH_UNIT_ARTICLE_REQ'
export const FETCH_UNIT_ARTICLE_SUCCESS = 'FETCH_UNIT_ARTICLE_SUCCESS'
export const FETCH_UNIT_ARTICLE_FAIL = 'FETCH_UNIT_ARTICLE_FAIL'


export const FETCH_UNIT_SUBJECT_REQ = 'FETCH_UNIT_SUBJECT_REQ'
export const FETCH_UNIT_SUBJECT_SUCCESS = 'FETCH_UNIT_SUBJECT_SUCCESS'
export const FETCH_UNIT_SUBJECT_FAIL = 'FETCH_UNIT_SUBJECT_FAIL'

export const PATCH_UNIT_ARTICLE = 'PATCH_UNIT_ARTICLE'

export const FETCH_GRADE_REQ = 'FETCH_GRADE_REQ'
export const FETCH_GRADE_SUCCESS = 'FETCH_GRADE_SUCCESS'
export const FETCH_GRADE_FAIL = 'FETCH_GRADE_FAIL'

export const FETCH_UNIT_REQ = 'FETCH_UNIT_REQ'
export const FETCH_UNIT_SUCCESS = 'FETCH_UNIT_SUCCESS'
export const FETCH_UNIT_FAIL = 'FETCH_UNIT_FAIL'

export const PATCH_UNIT = 'PATCH_UNIT'



