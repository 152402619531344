export * from './random'
export * from './history'
export * from './storage'
export * from './grade'

export const isSogou = () => {
    return location.host === 'shouji.sogou.com'
}

const ua = navigator.userAgent.toLowerCase()
export const isIOS =
    ua.match(/iPhone/i) || ua.match(/iPod/i) || ua.match(/iPad/i)
export const isAndroid = navigator.userAgent.match(/android/i)
export const isWeChat =
    ua.match(/MicroMessenger/i) == 'micromessenger' ||
    ua.match(/QQ\/[0-9]/i) == 'QQ' ||
    ua.match(/qq\/[0-9]/i) == 'qq' ||
    ua.match(/ qq/i) == ' qq'
